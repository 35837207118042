exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-index-js": () => import("./../../../src/pages/book/index.js" /* webpackChunkName: "component---src-pages-book-index-js" */),
  "component---src-pages-fellowship-index-js": () => import("./../../../src/pages/fellowship/index.js" /* webpackChunkName: "component---src-pages-fellowship-index-js" */),
  "component---src-pages-founders-series-index-js": () => import("./../../../src/pages/founders-series/index.js" /* webpackChunkName: "component---src-pages-founders-series-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-induction-dinner-index-js": () => import("./../../../src/pages/induction-dinner/index.js" /* webpackChunkName: "component---src-pages-induction-dinner-index-js" */),
  "component---src-pages-investors-mentors-index-js": () => import("./../../../src/pages/investors-mentors/index.js" /* webpackChunkName: "component---src-pages-investors-mentors-index-js" */),
  "component---src-pages-media-index-js": () => import("./../../../src/pages/media/index.js" /* webpackChunkName: "component---src-pages-media-index-js" */),
  "component---src-pages-nominate-index-js": () => import("./../../../src/pages/nominate/index.js" /* webpackChunkName: "component---src-pages-nominate-index-js" */),
  "component---src-templates-inductees-inductee-bio-js": () => import("./../../../src/templates/inductees/inductee-bio.js" /* webpackChunkName: "component---src-templates-inductees-inductee-bio-js" */)
}

